#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 840px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 60px 20px;
}

#brand {
  font-family: 'Rubik', sans-serif;
  font-size: 3em;
  margin: 0 0 .5em 0;
}

#form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: .25em 0;
}

#form > .label {
  font-size: 1.25em;
  color: #aaa;
}

#space-it {
  width: 100%;
  font-size: 2em;
  color: #ffffff;
  text-align: center;
  margin: 1em 0;
  padding: 0.3em;
  border: 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  box-shadow: 0 0 0 4px transparent;
  transition: box-shadow .15s ease-out;
}

#space-it:focus {
  box-shadow: 0 0 0 4px #56efff;
}

.hr-line {
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  margin: 30px 0px;
}

#result-heading {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
}

#result-area {
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  border-radius: 10px;
}

#result-area > .result {
  font-size: 2.5em;
  margin: .25em 0;
  font-weight: 300;
}

#copy {
  appearance: none;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5em;
  color: #000000;
  margin: 1em 0 0 0;
  border: 0;
  background-color: #46a3a3;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 4px rgb(51 64 82 / 58%);
}

#copy.-disabled {
  cursor: not-allowed;
}

#copied {
  appearance: none;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5em;
  color: #000000;
  margin: 1em 0 0 0;
  border: 0;
  background-color: #3b7280;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 4px rgb(51 64 82 / 58%);
}

#copy.-disabled {
  cursor: not-allowed;
}

.card {
  background: #363c44;
  padding: 30px;
  border-radius: 15px;
}